<template>
  <div class="card mt-3">
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-auto d-flex align-items-center">
            <img :src="require('@/assets/img/icons/user/user-icon-sm.png')" />
          </div>
          <div class="col">
            <h3>{{ this.$store.getters.fullUserName }}</h3>
            <ul class="list-group list-group-horizontal">
              <li class="list-group-item border-0 ps-0">
                <i class="fa fa-building text-primary me-2"></i
                >{{ this.$store.getters._companyName }}
              </li>
              <li class="list-group-item border-0">
                <i class="fa fa-at text-primary"></i>
                {{ this.$store.getters.email }}
              </li>
              <li class="list-group-item border-0">
                <i class="fa fa-phone text-primary me-2"></i>
                <template
                  v-if="
                    String.isNullOrWhiteSpace(
                      this.$store.getters.userInfo.mobileNumber
                    )
                  "
                >
                  <span>{{
                    $t(
                      "AccountSettings.PhoneNumberNotFound",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</span>
                </template>
                {{ this.$store.getters.userInfo.mobileNumber }}
              </li>
            </ul>
          </div>
        </div>
        <div class="row row-flex-cards mt-3">
          <div class="col-md-5">
            <div class="card mb-4 mb-xl-10">
              <div class="card-header border-0 p-2">
                <div class="card-title m-0 p-2">
                  <h3 class="fw-bold m-0">
                    {{
                      $t(
                        "AccountSettings.ActiveThemeViewAndChange",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </h3>
                </div>
              </div>
              <div class="collapse show">
                <div class="card-body border-top p-9">
                  <div class="theme-card" v-if="activeTheme">
                    <img
                      :src="
                        require(`@/assets/img/themes/${activeTheme.id}.png`)
                      "
                      class="img-fluid"
                      :alt="activeTheme.name"
                    />
                    <div class="text-center">
                      <button
                        class="btn btn-dark mt-n4 mb-3"
                        data-bs-toggle="modal"
                        data-bs-target="#modalChangeTheme"
                      >
                        <i class="bi bi-columns-gap"></i>
                        {{
                          $t(
                            "BaseModelFields.ChangeTheme",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="card mb-4 mb-xl-10">
              <div class="card-header border-0 p-2">
                <div class="card-title m-0 p-2">
                  <h3 class="fw-bold m-0">
                    {{
                      $t(
                        "AccountSettings.SignInMethod",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </h3>
                </div>
              </div>
              <div class="collapse show">
                <div class="card-body border-top p-9">
                  <div>
                    <div class="d-flex flex-wrap align-items-center">
                      <div id="sx_signin_email">
                        <div class="fs-6 fw-bold mb-1">
                          {{
                            $t(
                              "AccountSettings.EmailAddress",
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          }}
                        </div>
                        <div class="fw-semibold text-gray-600">
                          {{ this.$store.getters.email }}
                        </div>
                      </div>
                    </div>
                    <div class="separator separator-dashed my-6"></div>
                    <div class="d-flex flex-wrap align-items-center mb-10">
                      <div id="sx_signin_password">
                        <div class="fs-6 fw-bold mb-1">
                          {{
                            $t(
                              "AccountSettings.Password",
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          }}
                        </div>
                        <div class="fw-semibold text-gray-600">
                          ************
                        </div>
                      </div>
                      <div id="sx_signin_password_button" class="ms-auto">
                        <button
                          v-if="!resetPasswordRequestSending"
                          type="button"
                          class="btn btn-warning"
                          @click="resetPassword"
                          :disabled="resetingPassword"
                        >
                          <template v-if="resetingPassword">
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </template>
                          <template v-else>
                            {{
                              $t(
                                "AccountSettings.ResetPassword",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}
                          </template>
                        </button>
                      </div>
                    </div>
                    <div
                      class="notice mt-5 d-flex bg-light-primary rounded border-primary border border-dashed p-6"
                    >
                      <span class="d-flex align-items-center me-3">
                        <i class="fas fa-shield-alt text-primary fs-1"></i>
                      </span>
                      <div
                        class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap"
                      >
                        <div class="mb-3 mb-md-0 fw-semibold">
                          <h4 class="text-gray-900 fw-bold">
                            {{
                              $t(
                                "AccountSettings.SecureYourAccount",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}
                          </h4>
                          <div class="fs-6 text-gray-700 pe-7">
                            {{
                              $t(
                                "AccountSettings.TwoFactorAuthenticationDescription",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}
                          </div>
                        </div>
                        <button
                          v-if="false"
                          type="button"
                          class="btn btn-primary px-6 align-self-center text-nowrap"
                        >
                          {{
                            $t(
                              "AccountSettings.Enable",
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ChangeThemeModal />
</template>
<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { createToast } from "mosha-vue-toastify";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ChangeThemeModal from "@/components/custom/theme-helper/ChangeThemeModal.vue";
export default {
  name: "AccountSettings",
  data() {
    return {
      bootswatch: this.$root.bootswatch,
      activeTheme: null,
      phone: "",
      permissionAndUserGroups: [],
      languages: [
        {
          id: "tr",
          text: this.$t(
            "Language.tr",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          id: "en",
          text: this.$t(
            "Language.en",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      settings: {
        allowClear: true,
        theme: "bootstrap-5",
        language: this.$root.getSelect2Locale(this.$store.state.activeLang),
      },
      languageValue: this.$store.state.activeLang,
      resetingPassword: false,
      resetPasswordRequestSending: false,
    };
  },
  components: {
    ChangeThemeModal,
  },
  methods: {
    setActiveTheme() {
      var activeTheme =
          localStorage.getItem("activeTheme") || this.$store.state.activeTheme,
        bs = this.$root.bootswatch;

      activeTheme = activeTheme == "Default" ? bs.defaultTheme : activeTheme;

      this.activeTheme = bs.items.find((f) => f.id == activeTheme);
    },
    selectChangeLanguage({ id, text }) {
      this.languageValue = id;
    },
    setBreadcrumb() {
      setCurrentPageBreadcrumbs(
        String.format(
          "{0}",
          this.$t(
            "AccountSettings.Title",
            {},
            { locale: this.$store.state.activeLang }
          )
        ),
        []
      );

      this.$root.changeDocumentTitle(
        this.$t(
          "AccountSettings.Title",
          {},
          { locale: this.$store.state.activeLang }
        )
      );
    },
    notify() {
      createToast(
        this.$t(
          "BaseModelFields.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        ),
        {
          showIcon: true,
          position: "top-right",
          type: "success",
          transition: "zoom",
        }
      );
    },
    saveOverviewInfo() {
      this.$root.setCurrentUserLanguage(this.languageValue);
    },
    getResponseMessage(response) {
      return this.$t(
        "ForgotPassword.SuccessMessage",
        {},
        { locale: this.$store.state.activeLang }
      ).replaceAll("EMAIL_ADDRESS", this.$store.getters.email);
    },
    resetPassword() {
      this.resetingPassword = true;
      this.$appAxios
        .post("/PasswordReset", {
          email: this.$root.encrypt(this.$store.getters.email, false),
          clientUrl: `${window.location.origin}/#/`,
        })
        .then((result) => {
          this.resetingPassword = false;
          this.resetPasswordRequestSending = true;
          var response = result.data;
          Swal.fire({
            width: "auto",
            html:
              "<span class='fw-bold fs-5'>" +
              this.getResponseMessage(response) +
              "</span>",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText:
              "<i class='fa fa-times'></i>" +
              this.$t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              ),
            customClass: {
              confirmButton: "btn fw-bold btn-success",
            },
          });
        });
    },
  },
  mounted() {
    this.setBreadcrumb();
    this.phone = this.$store.getters.userInfo.mobileNumber;
    this.setActiveTheme();
  },
};
</script>
